@import "./fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply text-base scroll-smooth;
}

.container {
  @apply w-full lg:max-w-[1230px] xl:max-w-[1430px] px-8 sm:px-6 mx-auto;
}

.border-gradient {
  position: relative;
}

.border-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 15px;
  border: 1px solid transparent;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.5)) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.heading {
  @apply text-5xl md:text-6xl lg:text-7xl;
}

.button-outline {
  @apply border border-primary hover:border-primary-hover active:text-black hover:text-white active:bg-primary md:hover:bg-primary disabled:text-gray disabled:border-[#1E1E1E] disabled:bg-[#1E1E1E] hover:disabled:bg-[#1E1E1E] transition-colors;
}

.scrollbar {
  overflow-x: auto;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #917f30;
  border-radius: 3px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #2f2f2f;
  border-radius: 3px;
}

.scrollbar::-webkit-scrollbar {
  height: 4px;
}

.loading {
  background: linear-gradient(100deg, #15171C 30%, #21242c 50%, #15171C 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.task__content img {
  @apply my-6;
}

.guide__description a,
.task__content a {
  color: #eccf4d;
  text-decoration: underline;
}

.guide__description p + p {
  @apply mt-6;
}

.task__content p + p {
  @apply mt-6;
}