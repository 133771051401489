@font-face {
  font-family: Montserrat;
  src: url(..//fonts/Montserrat-Regular.woff2);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(..//fonts/Montserrat-Light.woff2);
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(..//fonts/Montserrat-Medium.woff2);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(..//fonts/Montserrat-SemiBold.woff2);
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(..//fonts/Montserrat-Bold.woff2);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(..//fonts/Montserrat-Black.woff2);
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}